import "../../assets/style/Footer.css";

function Footer({ className }) {
  console.log(className);

  return (
    <div
      className={`container-footer ${
        className == "container-system-collaborator full" ? "max-width" : ""
      }`}
    >
      © KERV - All Rights Reserved 2024.
    </div>
  );
}

export default Footer;
