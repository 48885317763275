import '../../assets/style/User.css'
import React, { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';


function User() {

    const [payload, setPayload] = useState({
        first_name: '',
        last_name: '',
        username: '',
        phone: '',
        email: '',
        password: ''
    });

    const handleChange = (name, value) => {
        setPayload({
            ...payload,
            [name]: value,
        });
    }

    const handleSubmit = (event) =>{
        event.preventDefault();

        try{
            axios.post(`${process.env.REACT_APP_API_HOST}/api/create/user`,payload)
            toast.success('Usuario cadastrado com sucesso!');
            setPayload({
                first_name: '',
                last_name: '',
                username: '',
                phone: '',
                email: '',
                password: ''
            });
        }catch (error){
            toast.error("Usuario nao cadastrado")
        }
    };

    return (
        <>
            <form className='form-user' onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="row-form">
                        <div className="col-sm-6">
                            <div className="input-field-box">
                                <input type="text" required onChange={(e) => handleChange("first_name", e.target.value)} value={payload.first_name} name="first_name"/>
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Primeiro nome</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-field-box">
                                <input type="text" required onChange={(e) => handleChange("last_name", e.target.value)} value={payload.last_name} name="last_name"/>
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Sobrenome</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-field-box">
                                <input type="text" required onChange={(e) => handleChange("username", e.target.value)} value={payload.username} name="username"/>
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Username</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-field-box">
                                <InputMask mask="(99) 99999-9999" type="text" required onChange={(e) => handleChange("phone", e.target.value)} value={payload.phone} name="phone"/>
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Celular</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-field-box">
                                <input type="text" required onChange={(e) => handleChange("email", e.target.value)} value={payload.email} name="email"/>
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>E-mail</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-field-box">
                                <input type="password" required onChange={(e) => handleChange("password", e.target.value)} value={payload.password} name="password"/>
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label>Password</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="col-sm-12 text-center btn-bottom">
                        <button type="submit" className="btn mat-btn">Salvar</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default User;
