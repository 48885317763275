import { useRef, useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import { FaCamera } from "react-icons/fa6";


const videoConstraints = {
  width: 420,
  height: 360,
  facingMode: "user"
};

function SnapCam({disabled, handleimgCam}){
  const [isCaptureEnable, setCaptureEnable] = useState(false);
  const [controlCapture, setControlCapture] = useState(true);
  const [controlFirstShot, setControlFirstShot] = useState(false);
  const webcamRef = useRef(null);
  const [url, setUrl] = useState(null);
  
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setUrl(imageSrc);
    }
    setCaptureEnable(false)
    setControlCapture(false)
    setControlFirstShot(true)
  }, [webcamRef]);

  const deleteCapture = () =>{
    setUrl(null)
    setControlCapture(true)
    handleimgCam({photo: null})
  };

  useEffect(() => {
    if(url && controlFirstShot){
      handleimgCam({photo: url})
      setControlFirstShot(false)
    }
  }, [url, handleimgCam]);

  return (
    <>
      {isCaptureEnable || (
        <>
        { disabled? 
            <button disabled onClick={() => setCaptureEnable(true)}><FaCamera></FaCamera>Tirar foto</button>      
          :
            <>
            {controlCapture && <button onClick={() => setCaptureEnable(true)}><FaCamera></FaCamera>Tirar foto</button>}
            </>
          }

        </>
      )}
      {isCaptureEnable && (
        <>
          <div>
            <Webcam
              audio={false}
              width={340}
              height={260}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </div>
          <button onClick={capture}>capture</button>
        </>
      )}
      {url && (
        <>
          <div>
            <img src={url} alt="Screenshot" />
          </div>
          <div>
            <button onClick={deleteCapture}>delete</button>
          </div>
        </>
      )}
    </>
  );
};

export default SnapCam;
