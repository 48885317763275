import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import axios from "axios";
import { PiNotePencilLight, PiTrashLight } from "react-icons/pi";
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateUser from "../User/UpdateUser";
import moment from "moment";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root'); // Definindo o elemento principal


function ListUsers() {

  const [currentPage, setCurrentPage] = useState(0); // Estado para controlar a página atual
  const [users, setUsers] = useState([]);
  const [modalDeleteIsOpen, setDeleteModalIsOpen] = useState(false); // Estado para controlar o modal de exclusão
  const [modalEditIsOpen, setEditModalIsOpen] = useState(false); // Estado para controlar o modal de edição
  const [selectedUserId, setSelectedUserId] = useState(null);

  async function fetchUsers(){
    try {
      let {data} = await axios.get(`${process.env.REACT_APP_API_HOST}/api/get/users`)
      setUsers(data)
    } catch (error) {
     console.log(error) 
    }
  }
  
  useEffect(() => {
    fetchUsers()
  },[]);

  const itemsPerPage = 14; // Quantidade de itens por página

  const offset = currentPage * itemsPerPage; // Cálculo do índice de início dos itens na página atual
  const currentPageData = Array.isArray(users) ? users.slice(offset, offset + itemsPerPage) : [];


  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const openDeleteModal = (userId) => {
    setSelectedUserId(userId);
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const openEditModal = (userId) => {
    setSelectedUserId(userId);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };


  // revisar 
  const handleDelete = async(event) => {
    event.preventDefault();
    try{
        await axios.delete(`${process.env.REACT_APP_API_HOST}/api/delete/user/${selectedUserId}`)
        fetchUsers()
        toast.success('Usuario deletado com sucesso!');
    }catch (error){
        toast.error("Usuario nao deletado")
    }
    closeDeleteModal();
  };


  return (
    <div>
      <Modal
        isOpen={modalDeleteIsOpen}
        onRequestClose={closeDeleteModal}
        style={customStyles}
        contentLabel="Delete User Modal"
        overlayClassName="custom-overlay-class"
        className="custom-modal-class"
      >
        <h2 className="title-modal">Excluir Usuário</h2>
          <p>Tem certeza que deseja excluir este usuário?</p>
        <div className="btn-modal-class">
          <button className='btn btn-cancel' onClick={closeDeleteModal}>Cancelar</button>
          <button className='btn btn-neutral' onClick={handleDelete}>Deletar</button>
        </div>
      </Modal>


      <Modal
        isOpen={modalEditIsOpen}
        onRequestClose={closeEditModal}
        style={customStyles}
        contentLabel="Edit User Modal"
        overlayClassName="custom-overlay-class"
        className="custom-modal-class-edit"
      >
        
        <div className="container-flex">
          <h2 className="title-modal">Atualizar usuário</h2>
          <div className='btn btn-cancel' onClick={closeEditModal}>X</div>
        </div>

        <UpdateUser userId={selectedUserId} onChildClick={fetchUsers} onClose={closeEditModal} />
      </Modal>

      <table className="styled-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Usuário</th>
            <th>Contato</th>
            <th>Data de Criação</th>
            <th colSpan="2">Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map(data => (
            <tr key={data.id}>
              <td>{data.id}</td>
              <td>{data.first_name}</td>
              <td>{data.username}</td>
              <td>{data.phone}</td>
              <td>{moment(data.created_at).format('DD/MM/YYYY HH:mm')}</td>
              <td><PiNotePencilLight title="Editar" onClick={() => openEditModal(data.id)} className='react-action-icons success' /></td>
              <td><PiTrashLight title="Excluir" onClick={() => openDeleteModal(data.id)} className='react-action-icons danger'/></td>
            </tr>
          ))}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"Anterior"}
        nextLabel={"Próxima"}
        breakLabel={"..."}
        pageCount={Math.ceil(users.length / itemsPerPage)} // Número total de páginas
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />

    </div>
  );
}

export default ListUsers;
