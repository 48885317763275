import TopBar from '../TopBar/';
import { useState } from 'react';
import SideBar from '../Sidebar/';

function Home() {
    
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const isSidebarOpen = sidebarOpen;

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <div className='container-home'>
            <TopBar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen}/>
            <SideBar isOpen={sidebarOpen} closeSidebar={closeSidebar}/>
        </div>
    );
}

export default Home;
