import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom"; // Removido BrowserRouter
import User from "../User/";
import Container from "../Container";
import Event from "../Approvals";
import Visitor from "../Visitor";
import {
  PiUsersThreeLight,
  PiGear,
  PiListBulletsLight,
  PiCaretUpLight,
  PiCaretDownLight,
  PiCircleFill,
  PiUserPlusLight,
  PiNotebookLight,
} from "react-icons/pi";
import ListUsers from "../User/ListUsers";
import ListVisitor from "../Visitor/ListVisitor";
import Hierarchy from "../Hierarchy";
import "../../assets/style/Links.css";

function Links({ isOpen, closeSidebar }) {
  const [isVisitorsOpen, setIsVisitorsOpen] = useState(false);
  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const [isConfOpen, setIsConfOpen] = useState(false);

  const toggleVisitorsDropdown = () => {
    setIsVisitorsOpen(!isVisitorsOpen);
  };

  const toggleConfigurationDropdown = () => {
    setIsConfOpen(!isConfOpen);
  };

  const toggleUsersDropdown = () => {
    setIsUsersOpen(!isUsersOpen);
  };

  const closeDropdowns = () => {
    setIsVisitorsOpen(false);
    setIsUsersOpen(false);
    setIsConfOpen(false);
  };

  const handleLinkClick = (e) => {
    closeSidebar();
    setIsVisitorsOpen(false);
    setIsUsersOpen(false);
  };

  let permission = localStorage.getItem("collaborator");

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Container
              classContainer={"container-system"}
              classContent={"content-table"}
              menu={"Aprovações"}
            >
              <Event />
            </Container>
          }
        />
        <Route
          path="/register/collaborator"
          element={
            <Container
              classContainer={`container-system-collaborator ${
                !permission ? "" : "full"
              }`}
              classContent={"content-system"}
              menu={"Cadastro colaboradores"}
            >
              <Visitor />
            </Container>
          }
        />
        <Route
          path="/list/collaborators"
          element={
            <Container
              classContainer={"container-system"}
              classContent={"content-table"}
              menu={"Lista colaboradores"}
            >
              <ListVisitor />
            </Container>
          }
        />
        <Route
          path="/approvals"
          element={
            <Container
              classContainer={"container-system"}
              classContent={"content-table"}
              menu={"Aprovações"}
            >
              <Event />
            </Container>
          }
        />
        <Route
          path="/register/user"
          element={
            <Container
              classContainer={"container-system"}
              classContent={"content-system"}
              menu={"Cadastro usuários"}
            >
              <User />
            </Container>
          }
        />
        <Route
          path="/list/users"
          element={
            <Container
              classContainer={"container-system"}
              classContent={"content-table"}
              menu={"Lista usuários"}
            >
              <ListUsers />
            </Container>
          }
        />
        <Route
          path="/conf/"
          element={
            <Container
              classContainer={"container-system"}
              classContent={"content-system"}
              menu={"Cadastro setores e cargos"}
            >
              <Hierarchy />
            </Container>
          }
        ></Route>
      </Routes>
      {!permission && (
        <ul
          className={isOpen ? "list-nav show-sidebar" : "list-nav hide-sidebar"}
        >
          <li className="nav-item row">
            <Link className="title-nav-link nav-hover" to="/approvals">
              <PiListBulletsLight className="react-icons-sidebar" />
              <a className="flex-link" onClick={handleLinkClick}>
                Aprovações
              </a>
            </Link>
          </li>
          <li className="nav-item row">
            <a
              onClick={toggleVisitorsDropdown}
              className={
                !isVisitorsOpen
                  ? "title-nav-link nav-hover"
                  : "title-nav-link nav-hover active"
              }
            >
              <PiUsersThreeLight className="react-icons-sidebar" />
              <div className="flex-link ">Colaboradores</div>
              {!isVisitorsOpen ? (
                <PiCaretUpLight className="react-icons-sidebar-up" />
              ) : (
                <PiCaretDownLight className="react-icons-sidebar-up" />
              )}
            </a>
            {isVisitorsOpen && (
              <ul className="dropdown-content show">
                <li className="nav-link-down">
                  <Link
                    onClick={closeDropdowns}
                    className="title-nav-link sub-item"
                    to="/list/collaborators"
                  >
                    <div className="center-marker">
                      <PiCircleFill className="pi-circle-sub-item" />
                      <a className="title-nav-link" onClick={handleLinkClick}>
                        Lista
                      </a>
                    </div>
                  </Link>
                </li>
                <li className="nav-link-down">
                  <Link
                    onClick={closeDropdowns}
                    className="title-nav-link sub-item"
                    to="/register/collaborator"
                  >
                    <div className="center-marker">
                      <PiCircleFill className="pi-circle-sub-item" />
                      <a className="title-nav-link" onClick={handleLinkClick}>
                        Cadastro
                      </a>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="nav-item row">
            <a
              onClick={toggleConfigurationDropdown}
              className={
                !isConfOpen
                  ? "title-nav-link nav-hover"
                  : "title-nav-link nav-hover active"
              }
            >
              <PiGear className="react-icons-sidebar" />
              <div className="flex-link">Configurações</div>
              {!isConfOpen ? (
                <PiCaretUpLight className="react-icons-sidebar-up" />
              ) : (
                <PiCaretDownLight className="react-icons-sidebar-up" />
              )}
            </a>
            {isConfOpen && (
              <ul className="dropdown-content">
                <li className="nav-item row">
                  <a
                    onClick={toggleUsersDropdown}
                    className={
                      !isUsersOpen
                        ? "title-nav-link nav-hover"
                        : "title-nav-link nav-hover"
                    }
                  >
                    <PiUserPlusLight className="react-icons-sidebar" />
                    <div className="flex-link">Usuário</div>
                    {!isUsersOpen ? (
                      <PiCaretUpLight className="react-icons-sidebar-up icons-left icon-right" />
                    ) : (
                      <PiCaretDownLight className="react-icons-sidebar-up icons-left icon-right" />
                    )}
                  </a>
                  {isUsersOpen && (
                    <ul className="dropdown-content">
                      <li className="nav-link-down">
                        <Link
                          onClick={closeDropdowns}
                          className="title-nav-link sub-item"
                          to="/list/users"
                        >
                          <div className="center-marker">
                            <PiCircleFill className="pi-circle-sub-item" />
                            <a
                              className="title-nav-link"
                              onClick={handleLinkClick}
                            >
                              Lista
                            </a>
                          </div>
                        </Link>
                      </li>
                      <li className="nav-link-down">
                        <Link
                          onClick={closeDropdowns}
                          className="title-nav-link sub-item"
                          to="/register/user"
                        >
                          <div className="center-marker">
                            <PiCircleFill className="pi-circle-sub-item" />
                            <a
                              className="title-nav-link"
                              onClick={handleLinkClick}
                            >
                              Cadastro
                            </a>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="nav-link-down">
                  <Link
                    className="title-nav-link nav-hover"
                    onClick={closeDropdowns}
                    to="/conf"
                  >
                    <PiNotebookLight className="react-icons-sidebar" />
                    <a
                      className="flex-link text-style"
                      onClick={handleLinkClick}
                    >
                      Cargos e Setores
                    </a>
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      )}
    </>
  );
}

export default Links;
