// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .react-action-icons{
    margin-right: 1em;
    font-size: 18px;
    cursor: pointer;
  }

  .success{
    color: green;
  }

  .danger{
    color: red;
  }


  .flex-actions-cll{
    display: flex;
    justify-content: space-around;
    padding: 19px 15px !important;
  }


.custom-modal-class-edit-collaborator {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 15px;
  height: 45em;
  inset: 51% auto auto 62% !important;
  outline: none;
  overflow: auto;
  padding: 20px;
  position: absolute;
  width: 44em;
  text-align: center;
}

@media only screen and (max-width: 768px){

  .custom-modal-class-edit-collaborator {
      inset: 50% auto auto 50% !important;
      position: absolute;
      border: 1px solid rgb(204, 204, 204);
      background: rgb(255, 255, 255);
      border-radius: 15px;
      overflow: auto;
      outline: none;
      padding: 20px;
      width: 20em;
      height: 33em;
  }

}`, "",{"version":3,"sources":["webpack://./src/assets/style/Collaborator.css"],"names":[],"mappings":";EACE;IACE,iBAAiB;IACjB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,UAAU;EACZ;;;EAGA;IACE,aAAa;IACb,6BAA6B;IAC7B,6BAA6B;EAC/B;;;AAGF;EACE,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,mCAAmC;EACnC,aAAa;EACb,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;;AAEA;;EAEE;MACI,mCAAmC;MACnC,kBAAkB;MAClB,oCAAoC;MACpC,8BAA8B;MAC9B,mBAAmB;MACnB,cAAc;MACd,aAAa;MACb,aAAa;MACb,WAAW;MACX,YAAY;EAChB;;AAEF","sourcesContent":["\n  .react-action-icons{\n    margin-right: 1em;\n    font-size: 18px;\n    cursor: pointer;\n  }\n\n  .success{\n    color: green;\n  }\n\n  .danger{\n    color: red;\n  }\n\n\n  .flex-actions-cll{\n    display: flex;\n    justify-content: space-around;\n    padding: 19px 15px !important;\n  }\n\n\n.custom-modal-class-edit-collaborator {\n  background: #fff;\n  border: 1px solid #ccc;\n  border-radius: 15px;\n  height: 45em;\n  inset: 51% auto auto 62% !important;\n  outline: none;\n  overflow: auto;\n  padding: 20px;\n  position: absolute;\n  width: 44em;\n  text-align: center;\n}\n\n@media only screen and (max-width: 768px){\n\n  .custom-modal-class-edit-collaborator {\n      inset: 50% auto auto 50% !important;\n      position: absolute;\n      border: 1px solid rgb(204, 204, 204);\n      background: rgb(255, 255, 255);\n      border-radius: 15px;\n      overflow: auto;\n      outline: none;\n      padding: 20px;\n      width: 20em;\n      height: 33em;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
