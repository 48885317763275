// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.permission-allow{
    color: #005500;
    font-size: 1.3em;
    cursor: pointer;
}

.permission-denied{
    color: red;
    font-size: 1.3em;
    cursor: pointer;
}

.padding-table-adjute{
    padding: 0px 0px !important;
}

.status-aproved{
    background: #28c76f;
    padding: 8px 9px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    outline: none;
    border: none;
}

.status-pending{
    background: #f90;
    padding: 8px 9px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    outline: none;
    border: none;
}


.status-denied{
    background: #ea5455;
    padding: 8px 9px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    outline: none;
    border: none;
}

.permission-detail{
    color: #3a5ad5;
    font-size: 1.3em;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
  
    .styled-table tbody tr:last-of-type {
      border-bottom: 2px solid #dfdfdf;
    }

    .flex-actions{
        display: flex;
        justify-content: space-around;
        padding: 19px 15px !important;
    }

}`, "",{"version":3,"sources":["webpack://./src/assets/style/Approvals.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,YAAY;AAChB;;;AAGA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;;AAEA;;IAEI;MACE,gCAAgC;IAClC;;IAEA;QACI,aAAa;QACb,6BAA6B;QAC7B,6BAA6B;IACjC;;AAEJ","sourcesContent":[".permission-allow{\n    color: #005500;\n    font-size: 1.3em;\n    cursor: pointer;\n}\n\n.permission-denied{\n    color: red;\n    font-size: 1.3em;\n    cursor: pointer;\n}\n\n.padding-table-adjute{\n    padding: 0px 0px !important;\n}\n\n.status-aproved{\n    background: #28c76f;\n    padding: 8px 9px;\n    border-radius: 6px;\n    color: white;\n    font-weight: bold;\n    outline: none;\n    border: none;\n}\n\n.status-pending{\n    background: #f90;\n    padding: 8px 9px;\n    border-radius: 6px;\n    color: white;\n    font-weight: bold;\n    outline: none;\n    border: none;\n}\n\n\n.status-denied{\n    background: #ea5455;\n    padding: 8px 9px;\n    border-radius: 6px;\n    color: white;\n    font-weight: bold;\n    outline: none;\n    border: none;\n}\n\n.permission-detail{\n    color: #3a5ad5;\n    font-size: 1.3em;\n    cursor: pointer;\n}\n\n@media only screen and (max-width: 768px) {\n  \n    .styled-table tbody tr:last-of-type {\n      border-bottom: 2px solid #dfdfdf;\n    }\n\n    .flex-actions{\n        display: flex;\n        justify-content: space-around;\n        padding: 19px 15px !important;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
