import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { PiEyeLight, PiCheckCircleLight, PiXCircleLight } from "react-icons/pi";
import '../../assets/style/Approvals.css'
import '../../assets/style/Table.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import moment from 'moment'
import UpdateVisitor from "../Visitor/UpdateVisitor";
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root'); // Definindo o elemento principal


function Event() {

    const [currentPage, setCurrentPage] = useState(0); // Estado para controlar a página atual
    const [collaborators, setCollaborators] = useState([]);
    const [modalEditIsOpen, setEditModalIsOpen] = useState(false); // Estado para controlar o modal de edição
    const [selectedUserId, setSelectedUserId] = useState(null);


    async function fetchCollaborators(){
      try {
        let {data} = await axios.get(`${process.env.REACT_APP_API_HOST}/api/get/collaborators`)
        setCollaborators(data) 
      } catch (error) {
        console.log(error)
      }
    }
    
    useEffect(() => {
      fetchCollaborators()
    },[]);
  
    const closeEditModal = () => {
      setEditModalIsOpen(false);
    };


    const itemsPerPage = 14; // Quantidade de itens por página
    const offset = currentPage * itemsPerPage; // Cálculo do índice de início dos itens na página atual
    const currentPageData = Array.isArray(collaborators) ? collaborators.slice(offset, offset + itemsPerPage) : [];

    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
    };

    const handleAllow = async (id) => {
      try {
        const payloadData = {
          status: 'A'
        };
        await axios.put(`${process.env.REACT_APP_API_HOST}/api/permission/collaborator/${id}`, payloadData);
        toast.success('Usuário permitido com sucesso!');
        fetchCollaborators();
      } catch (error) {
        console.log(error)
      }
    }

    const handleDenied = async (id) => {
      try {
        const payloadData = {
          status: 'N'
        };
        await axios.put(`${process.env.REACT_APP_API_HOST}/api/permission/collaborator/${id}`, payloadData);
        toast.error('Usuário não autorizado!');
        fetchCollaborators();
      } catch (error) {
        console.log(error)
      }
    }

    const handleDetail = (userId) => {
      setSelectedUserId(userId);
      setEditModalIsOpen(true);
    };

    return (
      <>
        <Modal
          isOpen={modalEditIsOpen}
          onRequestClose={closeEditModal}
          style={customStyles}
          contentLabel="Edit User Modal"
          overlayClassName="custom-overlay-class"
          className="custom-modal-class-edit"
          >
          
          <div className="container-flex">
            <h2 className="title-modal">Informações do colaborador</h2>
            <div className='btn btn-cancel' onClick={closeEditModal}>X</div>
          </div>

          <UpdateVisitor userId={selectedUserId} onClose={closeEditModal} disabled={true}/>
        </Modal>
        <table className="styled-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Celular</th>
              <th>Empresa</th>
              <th>Status</th>
              <th>Data de Criação	</th>
              <th colSpan="3">Ações</th>
            </tr>
          </thead>
          <tbody>
            {currentPageData.map(data => (
              <tr key={data.collaborator.id}>
                <td>{data.collaborator.id}</td>
                <td>{data.collaborator.first_name}</td>
                <td>{data.collaborator.phone}</td>
                <td>{data.collaborator.company}</td>
                <td className="padding-table-adjute">
                  {data.status_collaborator.status === 'A' ? <button className="status-aproved">Aprovado</button> : 
                    data.status_collaborator.status === 'N' ? <button className="status-denied">Negado</button> :
                    <button className="status-pending">Pendente</button>
                  }
                </td>
                <td>{moment(data.status_collaborator.created_at).format('DD/MM/YYYY HH:mm')}</td>
                <td><PiEyeLight onClick={() => handleDetail(data.collaborator.id)} className="permission-detail"/></td>
                <td><PiCheckCircleLight onClick={() => handleAllow(data.collaborator.id)} className="permission-allow"/></td>
                <td><PiXCircleLight onClick={() =>  handleDenied(data.collaborator.id)} className="permission-denied"/></td>
              </tr>
            ))}
          </tbody>
        </table>
  
        <ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Próxima"}
          breakLabel={"..."}
          pageCount={Math.ceil(collaborators.length / itemsPerPage)} // Número total de páginas
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </>
    );
  }

export default Event;