// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-icons-sidebar{
    margin-right: 1em;
    font-size: 20px;
}

.full{
    width: 90%;
    left: 5%;
}

@media only screen and (max-width: 768px) {
    .full{
        width: 96%;
        left: 2%;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/style/Links.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI;QACI,UAAU;QACV,QAAQ;IACZ;AACJ","sourcesContent":[".react-icons-sidebar{\n    margin-right: 1em;\n    font-size: 20px;\n}\n\n.full{\n    width: 90%;\n    left: 5%;\n}\n\n@media only screen and (max-width: 768px) {\n    .full{\n        width: 96%;\n        left: 2%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
