import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { PiNotePencilLight, PiTrashLight } from "react-icons/pi";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/style/Collaborator.css";
import "../../assets/style/Table.css";
import UpdateVisitor from "../Visitor/UpdateVisitor";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ListVisitor() {
  const [currentPage, setCurrentPage] = useState(0);
  const [collaborator, setCollaborators] = useState([]);
  const [modalDeleteIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [modalEditIsOpen, setEditModalIsOpen] = useState(false); // Estado para controlar o modal de edição
  const itemsPerPage = 14;
  const offset = currentPage * itemsPerPage;
  const currentPageData = Array.isArray(collaborator)
    ? collaborator.slice(offset, offset + itemsPerPage)
    : [];

  async function fetchCollaborators() {
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/get/collaborators`
      );
      setCollaborators(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchCollaborators();
  }, []);

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_HOST}/api/delete/collaborator/${selectedUserId}`
      );
      toast.success("Usuário deletado com sucesso!");
      fetchCollaborators();
    } catch (error) {
      toast.error("Usuário nao deletado");
    }

    closeDeleteModal();
  };

  const openDeleteModal = (userId) => {
    setSelectedUserId(userId);
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const openEditModal = (userId) => {
    setSelectedUserId(userId);
    setEditModalIsOpen(true);
  };

  return (
    <div>
      <Modal
        isOpen={modalDeleteIsOpen}
        onRequestClose={closeDeleteModal}
        style={customStyles}
        contentLabel="Delete User Modal"
        overlayClassName="custom-overlay-class"
        className="custom-modal-class"
      >
        <h2 className="title-modal">Excluir Usuário</h2>
        <p>Tem certeza que deseja excluir este usuário?</p>
        <div className="btn-modal-class">
          <button className="btn btn-cancel" onClick={closeDeleteModal}>
            Cancelar
          </button>
          <button className="btn btn-neutral" onClick={handleDelete}>
            Deletar
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalEditIsOpen}
        onRequestClose={closeEditModal}
        style={customStyles}
        contentLabel="Edit User Modal"
        overlayClassName="custom-overlay-class"
        className="custom-modal-class-edit-collaborator"
      >
        <div className="container-flex">
          <h2 className="title-modal">Atualizar Collaborador</h2>
          <div className="btn btn-cancel" onClick={closeEditModal}>
            X
          </div>
        </div>

        <UpdateVisitor
          userId={selectedUserId}
          onChildClick={fetchCollaborators}
          onClose={closeEditModal}
        />
      </Modal>

      <table className="styled-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Contato</th>
            <th>Empresa</th>
            <th>Data de Criação</th>
            <th colSpan="2">Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((data) => (
            <tr key={data.collaborator.id}>
              <td>{data.collaborator.id}</td>
              <td>{data.collaborator.first_name}</td>
              <td>{data.collaborator.phone}</td>
              <td>{data.collaborator.company}</td>
              <td>
                {moment(data.collaborator.created_at).format(
                  "DD/MM/YYYY HH:mm"
                )}
              </td>
              <td>
                <PiNotePencilLight
                  title="Editar"
                  onClick={() => openEditModal(data.collaborator.id)}
                  className="react-action-icons success"
                />
              </td>
              <td>
                <PiTrashLight
                  title="Excluir"
                  onClick={() => openDeleteModal(data.collaborator.id)}
                  className="react-action-icons danger"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ReactPaginate
        previousLabel={"Anterior"}
        nextLabel={"Próxima"}
        breakLabel={"..."}
        pageCount={Math.ceil(collaborator.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default ListVisitor;
