// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-footer {
  background: #c2c2c257;
  bottom: 0;
  width: 80%;
  height: 2.3em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  margin-left: 20%;
  border-top: 1px solid #46464669;
}

.container-footer.max-width {
  width: 100%;
  margin-left: 0%;
}

@media only screen and (max-width: 768px) {
  .container-footer {
    background: #c2c2c257;
    bottom: 0;
    width: 100%;
    height: 2.3em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: bold;
    margin-left: 0%;
    border-top: 1px solid #46464669;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/style/Footer.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE;IACE,qBAAqB;IACrB,SAAS;IACT,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,+BAA+B;EACjC;AACF","sourcesContent":[".container-footer {\n  background: #c2c2c257;\n  bottom: 0;\n  width: 80%;\n  height: 2.3em;\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: black;\n  font-weight: bold;\n  margin-left: 20%;\n  border-top: 1px solid #46464669;\n}\n\n.container-footer.max-width {\n  width: 100%;\n  margin-left: 0%;\n}\n\n@media only screen and (max-width: 768px) {\n  .container-footer {\n    background: #c2c2c257;\n    bottom: 0;\n    width: 100%;\n    height: 2.3em;\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: black;\n    font-weight: bold;\n    margin-left: 0%;\n    border-top: 1px solid #46464669;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
