import '../../assets/style/TopBar.css'
import { FaCircleUser } from "react-icons/fa6";

function TopBar({toggleSidebar, isSidebarOpen}) {

    let permission = localStorage.getItem('collaborator')

    const handleClick = (e)=> {
        localStorage.removeItem('token')
        localStorage.removeItem('collaborator')
        window.location.reload()
    }

    return (
        <div className="container-topbar">
            <header className="header">
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className={`menu-icon ${isSidebarOpen ? 'open' : 'close'}`} onClick={toggleSidebar} htmlFor="menu-btn">
                <span className="navicon"></span>
            </label>
            { !permission ?
                <a className="logo" onClick={handleClick}><FaCircleUser /></a>
                : 
                <a className="exit" onClick={handleClick}>Sair</a>
            }
            </header>
        </div>
    );
}

export default TopBar;