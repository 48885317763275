import React from "react";
import "../../assets/style/Container.css";
import Footer from "../Footer";

function Container({ children, classContainer, classContent, menu }) {
  return (
    <>
      <div className={classContainer}>
        <div className="breadcrumb-list">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">Inicio</li>
            <li className="breadcrumb-item">></li>
            <li className="breadcrumb-item-active">{menu}</li>
          </ol>
        </div>
        <div className={classContent}>{children}</div>
      </div>
      <Footer className={classContainer} />
    </>
  );
}

export default Container;
