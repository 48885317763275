import { useState, useEffect } from "react";
import "../../assets/style/Hierarchy.css";
import axios from "axios";
import { PiXLight, PiPlusSquareLight } from "react-icons/pi";

function Hierarchy() {
  const [sectors, setSectors] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [sector, setSector] = useState("");
  const [wtfunction, setWtfunction] = useState("");

  async function fetchJobs() {
    try {
      var data = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/get/jobs`
      );
      setJobs(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSectors() {
    try {
      let data = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/get/sectors`
      );
      setSectors(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchSectors();
    fetchJobs();
  }, []);

  const handleSector = async (e) => {
    e.preventDefault();
    const data = { name: sector };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/create/sector`,
        data
      );
      setSector("");
      fetchSectors();
    } catch (error) {
      console.log(error);
    }
  };

  const handleJobFunction = async (e) => {
    e.preventDefault();
    const data2 = { name: wtfunction };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/create/job`,
        data2
      );
      setWtfunction("");
      fetchJobs();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (e, name, id) => {
    e.preventDefault();
    try {
      if (name == "setor") {
        await axios.delete(
          `${process.env.REACT_APP_API_HOST}/api/delete/sector/${id}`
        );
        fetchSectors();
      } else {
        await axios.delete(
          `${process.env.REACT_APP_API_HOST}/api/delete/job/${id}`
        );
        fetchJobs();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (name, value) => {
    if (name === "sector") {
      setSector(value);
    } else if (name === "wtfunction") {
      setWtfunction(value);
    }
  };

  return (
    <div className="container-configuration">
      <form className="form-user">
        <div className="row">
          <div className="form-group">
            <div className="row-form-x">
              <div className="col-sm-hierarchy">
                <div className="input-field-box-hierarchy">
                  <input
                    type="text"
                    required
                    onChange={(e) => handleChange("sector", e.target.value)}
                    value={sector}
                    name="sector"
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Setor</label>
                </div>
                <button onClick={handleSector} className="react-icon-plus">
                  <PiPlusSquareLight />
                </button>
              </div>
              {sectors && sectors.length > 0 && (
                <div className="list-word-sector">
                  {sectors.map((abc) => (
                    <div className="itens-word" key={abc.id}>
                      <p className="flex-word">{abc.name}</p>
                      <button
                        onClick={(e) => handleDelete(e, "setor", abc.id)}
                        className="btn-close-light flex-word"
                      >
                        <PiXLight />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <form className="form-user">
        <div className="row">
          <div className="form-group">
            <div className="row-form-x">
              <div className="col-sm-hierarchy">
                <div className="input-field-box-hierarchy">
                  <input
                    type="text"
                    required
                    onChange={(e) => handleChange("wtfunction", e.target.value)}
                    value={wtfunction}
                    name="wtfunction"
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Cargo</label>
                </div>
                <button onClick={handleJobFunction} className="react-icon-plus">
                  <PiPlusSquareLight />
                </button>
              </div>
              {jobs && jobs.length > 0 && (
                <div className="list-word-job">
                  {jobs.map((abc) => (
                    <div className="itens-word" key={abc.id}>
                      <p className="flex-word">{abc.name}</p>
                      <button
                        onClick={(e) => handleDelete(e, "cargo", abc.id)}
                        className="btn-close-light flex-word"
                      >
                        <PiXLight />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Hierarchy;
