import './App.css';
import React from 'react';
import Login from '../src/components/Login/';
import { BrowserRouter } from 'react-router-dom';
// Importe o ToastContainer de 'react-toastify'
import { ToastContainer } from 'react-toastify';
// Importe o CSS do ToastContainer
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
          <ToastContainer
            autoClose={2000} // Tempo de fechamento automático
            toastStyle={{
              marginTop: '100px', // Estilo de posicionamento
            }}
          />
        <Login />
      </BrowserRouter>
    </div>
  );
}

export default App;
