import '../../assets/style/User.css'
import React, { useEffect, useRef, useState } from "react";
import SnapCam from "../SnapCam/"
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputMask from 'react-input-mask';
import Select from "react-select";
import '../../assets/style/Container.css'



function Visitor() {

    const [image, setImage] = useState('');
    const [camPhoto, setCamPhoto] = useState(false)
    const [sectors, setSectors] = useState(null)
    const [jobs, setJobs] = useState(null)
    const [allowFormSubmission, setAllowFormSubmission] = useState(true);

    const [payload, setPayload] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        cpf: '',
        company: '',
        photo_b64: '',
        archive_b64: '',
        sector: '',
        job: ''
    });


    const handleChange = (name, value) => {
        setPayload({
            ...payload,
            [name]: value,
            archive_b64: image
        });
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        if (!allowFormSubmission) {
            event.preventDefault();
            try{
                axios.post(`${process.env.REACT_APP_API_HOST}/api/create/collaborator`,payload)
                toast.success('Usuario cadastrado com sucesso!');
                setPayload({
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    cpf: '',
                    company: '',
                    photo_b64: '',
                    archive_b64: '',
                });
                handleReset()
            }catch (error){
                toast.error("Usuario nao cadastrado")
            }
        }
        setAllowFormSubmission(true);
    };

    const handleReset = (e) => {
        if(inputFile.current){
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file"
        }
        setImage('')
        setCamPhoto(false)
    }

    const MAX_IMAGE_SIZE_MB = 2;
    const MAX_IMAGE_WIDTH = 800;
    const MAX_IMAGE_HEIGHT = 300;
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file && file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
        alert(`A imagem selecionada é muito grande. Por favor, selecione uma imagem menor que ${MAX_IMAGE_SIZE_MB} MB.`);
        return;
      }
      const reader = new FileReader();
      reader.onload = function(event) {
        const img = new Image();
        img.onload = function() {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let newWidth = img.width;
          let newHeight = img.height;
          if (newWidth > MAX_IMAGE_WIDTH) {
            newHeight *= MAX_IMAGE_WIDTH / newWidth;
            newWidth = MAX_IMAGE_WIDTH;
          }
          if (newHeight > MAX_IMAGE_HEIGHT) {
            newWidth *= MAX_IMAGE_HEIGHT / newHeight;
            newHeight = MAX_IMAGE_HEIGHT;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          const resizedImageBase64 = canvas.toDataURL('image/jpeg');
          setImage(resizedImageBase64)
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    };
  

    async function fetchJobs(){
        try{

            var data = await axios.get(`${process.env.REACT_APP_API_HOST}/api/get/jobs`)
            setJobs(data.data)

        }catch (error){
            console.log(error)
        }
    }


    async function fetchSectors(){
        try{
            let data = await axios.get(`${process.env.REACT_APP_API_HOST}/api/get/sectors`)
            setSectors(data.data)
        }catch (error){
            console.log(error)
        }
    }


    useEffect(() => {
        fetchSectors();
        fetchJobs();
        if (image) {
            setAllowFormSubmission(false);
            setPayload({
                ...payload,
                archive_b64: image
            });
        }

    }, [image]);
    

    const handlePhoto = (event) => {
        setAllowFormSubmission(false);
        setCamPhoto(false)
        if (event.photo != null){
            setCamPhoto(true)
        }
        setPayload({
            ...payload,
            photo_b64: event.photo
        });
    }


    const inputFile = useRef(null)

    return (
        <>
            <ToastContainer
                toastStyle={{
                    marginTop: '100px',
                }}
            />
            <form className='form-user' onSubmit={handleSubmit}>
                <div className="containerx">
                    <div className="row">
                        <div className="offset-md-2 col-md-8">
                            <div className="mat-cards">
                                <div className="form-group">
                                    <div className="row-form">
                                        <div className="col-sm-6">
                                            <div className="input-field-box">
                                                <input type="text" required onChange={(e) => handleChange("first_name", e.target.value)} value={payload.first_name} name="first_name"/>
                                                <span className="highlight"></span>
                                                <span className="bar"></span>
                                                <label>Primeiro nome</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-field-box">
                                                <input type="text" required onChange={(e) => handleChange("last_name", e.target.value)} value={payload.last_name} name="last_name"/>
                                                <span className="highlight"></span>
                                                <span className="bar"></span>
                                                <label>Sobrenome</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-field-box">
                                                <InputMask mask="(99) 99999-9999" type="text" required onChange={(e) => handleChange("phone", e.target.value)} value={payload.phone} name="phone"/>
                                                <span className="highlight"></span>
                                                <span className="bar"></span>
                                                <label>Celular</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-field-box">
                                                <input type="text" required onChange={(e) => handleChange("email", e.target.value)} value={payload.email} name="email"/>
                                                <span className="highlight"></span>
                                                <span className="bar"></span>
                                                <label>E-mail</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-field-box">
                                                <InputMask mask="999.999.999-99" type="text" required onChange={(e) => handleChange("cpf", e.target.value)} value={payload.cpf} name="cpf"/>
                                                <span className="highlight"></span>
                                                <span className="bar"></span>
                                                <label>CPF</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-field-box">
                                                <input type="text" required onChange={(e) => handleChange("company", e.target.value)} value={payload.company} name="company"/>
                                                <span className="highlight"></span>
                                                <span className="bar"></span>
                                                <label>Empresa</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-field-box field-select">
                                            <p className='title-select'>Setor</p>
                                            <Select
                                                defaultValue={sectors}
                                                onChange={(e) => handleChange("sector", e.name)}
                                                placeholder="Selecione um cargo..."
                                                options={sectors}
                                            />
                                            
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-field-box field-select">
                                            <p className='title-select'>Cargo</p>
                                            <Select
                                                defaultValue={jobs}
                                                onChange={(e) => handleChange("job", e.name)}
                                                placeholder="Selecione um cargo..."
                                                options={jobs}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <p>Voce pode tirar uma foto com a sua camera</p>
                                    <br/>
                                    <br/>
                                    {image ? <SnapCam handleimgCam={handlePhoto} disabled={true}/> : <SnapCam handleimgCam={handlePhoto} disabled={false}/>}
                                    <br/>
                                    <br/>
                                    <p>ou fazer o upload de uma foto</p>
                                    <br/>
                                    <br/>
                                    <div>
                                        {image ?  
                                            <button onClick={handleReset}>remover img</button>                                        
                                        :   camPhoto === true ?
                                            <input type="file" disabled accept="image/*" onChange={handleImageChange} ref={inputFile} name='archive_b64'/>
                                        : 
                                            <input type="file" accept="image/*" onChange={handleImageChange} ref={inputFile} name='archive_b64'/>}
                                        {image && (
                                            <div>
                                            <h3>Pré-visualização da Imagem</h3>
                                            <img src={image} alt="Imagem Carregada" style={{ maxWidth: '100%' }} />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Outros campos de entrada vão aqui */}

                                <div className="form-group form-submit">
                                    <div className="col-sm-12 text-center btn-bottom">
                                    <button type="submit" className="btn mat-btn">Salvar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Visitor;