import React, { useEffect, useState } from "react";
import SnapCam from "../SnapCam/";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import InputMask from "react-input-mask";

function UpdateVisitor({ userId, onChildClick, onClose, disabled }) {
  const [collaboratorIdData] = useState(userId);
  const [preImage, sePreImage] = useState(null);
  const [camPhoto, setCamPhoto] = useState(null);
  const [image, setImage] = useState("");

  const [payload, setPayload] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    cpf: "",
    company: "",
    photo_b64: "",
    archive_b64: "",
    sector: "",
    job: "",
  });

  const [sectors, setSectors] = useState(null);
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    if (image) {
      setPayload({
        ...payload,
        archive_b64: image,
      });
    } else if (camPhoto) {
      // if (camPhoto) {
      setPayload((prevPayload) => ({
        ...prevPayload,
        photo_b64: camPhoto,
      }));
      // }
    } else {
      async function fetchUser() {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_HOST}/api/get/collaborator/${collaboratorIdData}`
          );
          sePreImage(data.archive_b64);
          setCamPhoto(data.photo_b64);
          setPayload(data);
        } catch (error) {
          console.error("Erro ao obter os dados do usuário:", error);
        }
      }
      async function fetchJobs() {
        try {
          var data = await axios.get(
            `${process.env.REACT_APP_API_HOST}/api/get/jobs`
          );
          setJobs(data.data);
        } catch (error) {
          console.log(error);
        }
      }

      async function fetchSectors() {
        try {
          let data = await axios.get(
            `${process.env.REACT_APP_API_HOST}/api/get/sectors`
          );
          setSectors(data.data);
        } catch (error) {
          console.log(error);
        }
      }

      fetchJobs();
      fetchSectors();
      fetchUser();
    }
  }, [collaboratorIdData, image, camPhoto]);

  const handleChange = async (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    if (name == "job") {
      setPayload({
        ...payload,
        job: [
          {
            value: value,
            label: value,
          },
        ],
      });
    } else if (name == "sector") {
      setPayload({
        ...payload,
        sector: [
          {
            value: value,
            label: value,
          },
        ],
      });
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      const c = {
        ...payload,
        job: payload.job[0].label,
        sector: payload.sector[0].label,
      };
      await axios.put(
        `${process.env.REACT_APP_API_HOST}/api/update/collaborator/${collaboratorIdData}`,
        c
      );
      toast.success("Usuario cadastrado com sucesso!");
      setPayload({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        cpf: "",
        company: "",
        photo_b64: "",
        archive_b64: "",
      });
      onChildClick();
      onClose();
    } catch (error) {
      toast.error("Usuario nao cadastrado");
    }
  };

  const MAX_IMAGE_SIZE_MB = 2;
  const MAX_IMAGE_WIDTH = 600;
  const MAX_IMAGE_HEIGHT = 300;
  const handleImageChange = (event) => {
    sePreImage(null);
    const file = event.target.files[0];
    if (file && file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      alert(
        `A imagem selecionada é muito grande. Por favor, selecione uma imagem menor que ${MAX_IMAGE_SIZE_MB} MB.`
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        let newWidth = img.width;
        let newHeight = img.height;
        if (newWidth > MAX_IMAGE_WIDTH) {
          newHeight *= MAX_IMAGE_WIDTH / newWidth;
          newWidth = MAX_IMAGE_WIDTH;
        }
        if (newHeight > MAX_IMAGE_HEIGHT) {
          newWidth *= MAX_IMAGE_HEIGHT / newHeight;
          newHeight = MAX_IMAGE_HEIGHT;
        }
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const resizedImageBase64 = canvas.toDataURL("image/jpeg");
        setImage(resizedImageBase64);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handlePhoto = (event) => {
    setCamPhoto(event.photo);
  };

  return (
    <div>
      <ToastContainer
        toastStyle={{
          marginTop: "100px",
        }}
      />
      <form className="form-user" onSubmit={handleUpdate}>
        <div className="containerx">
          <div className="row">
            <div className="offset-md-2 col-md-8">
              <div className="mat-cards">
                <div className="form-group center">
                  <div className="row-form">
                    <div className="col-sm-6">
                      <div className="input-field-box">
                        <input
                          type="text"
                          disabled={disabled ? true : false}
                          required
                          onChange={(e) =>
                            handleChange("first_name", e.target.value)
                          }
                          value={payload.first_name}
                          name="first_name"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Primeiro nome</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-field-box">
                        <input
                          type="text"
                          disabled={disabled ? true : false}
                          required
                          onChange={(e) =>
                            handleChange("last_name", e.target.value)
                          }
                          value={payload.last_name}
                          name="last_name"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Sobrenome</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-field-box">
                        <InputMask
                          mask="(99) 99999-9999"
                          type="text"
                          disabled={disabled ? true : false}
                          required
                          onChange={(e) =>
                            handleChange("phone", e.target.value)
                          }
                          value={payload.phone}
                          name="phone"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Celular</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-field-box">
                        <input
                          type="text"
                          disabled={disabled ? true : false}
                          required
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                          value={payload.email}
                          name="email"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>E-mail</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-field-box">
                        <InputMask
                          mask="999.999.999-99"
                          disabled={disabled ? true : false}
                          type="text"
                          required
                          onChange={(e) => handleChange("cpf", e.target.value)}
                          value={payload.cpf}
                          name="cpf"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>CPF</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-field-box">
                        <input
                          type="text"
                          disabled={disabled ? true : false}
                          required
                          onChange={(e) =>
                            handleChange("company", e.target.value)
                          }
                          value={payload.company}
                          name="company"
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Empresa</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-field-box field-select">
                        <p className="title-select">Setor</p>
                        <Select
                          value={payload.sector}
                          isDisabled={disabled ? true : false}
                          onChange={(e) => handleChange("sector", e.name)}
                          placeholder="Selecione um setor..."
                          options={sectors}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input-field-box field-select">
                        <p className="title-select">Cargo</p>
                        <Select
                          value={payload.job}
                          isDisabled={disabled ? true : false}
                          onChange={(e) => handleChange("job", e.name)}
                          placeholder="Selecione um cargo..."
                          options={jobs}
                        />
                      </div>
                    </div>
                  </div>
                  {!disabled ? (
                    <>
                      <p>Voce pode tirar uma foto com a sua camera</p>
                      <br />
                      <br />
                      {image ? (
                        <SnapCam disabled={true} />
                      ) : (
                        <SnapCam handleimgCam={handlePhoto} disabled={false} />
                      )}
                      <br />
                      <img src={camPhoto} />
                      <br />
                      <p>ou fazer o upload de uma foto</p>
                      <br />
                      <br />
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          name="archive_b64"
                        />
                        {image && (
                          <div>
                            <h3>Visualização da Imagem</h3>
                            <img
                              src={image}
                              alt="Imagem Carregada"
                              style={{ maxWidth: "100%" }}
                            />
                          </div>
                        )}
                        {preImage != null ? (
                          <>
                            <br />
                            <br />
                            <img
                              src={preImage}
                              alt="Imagem Carregada"
                              style={{ maxWidth: "100%" }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <br />
                      <div className="form-group form-submit">
                        <div className="col-sm-12 text-center btn-bottom">
                          <button type="submit" className="btn mat-btn">
                            Salvar
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {camPhoto ? (
                        <>
                          Foto Colaborador
                          <br />
                          <img src={camPhoto} />
                        </>
                      ) : (
                        <>
                          Foto Colaborador
                          <br />
                          <img
                            src={preImage}
                            alt="Imagem Carregada"
                            style={{ maxWidth: "100%" }}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateVisitor;
