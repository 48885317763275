import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../../assets/style/SideBar.css'
import Links from '../Links/';

function SideBar({isOpen, closeSidebar}) {
    return (
        <>
            <Links isOpen={isOpen} closeSidebar={closeSidebar}/>
        </>
    );
}

export default SideBar;
