import React, { useState } from "react";
import "../../assets/style/Login.css";
import Home from "../Home/";
import { useNavigate } from "react-router-dom";

function Login() {
  const [showContainer, setShowContainer] = useState(true);
  const [isToken, setIsToken] = useState(false);
  const [payload, setPayload] = useState({
    username: "",
    password: "",
  });

  const handleChange = (name, value) => {
    setPayload({
      ...payload,
      [name]: value,
    });
  };

  let navigate = useNavigate();

  // adjuted temporaliy for login
  const handleRedirect = (e) => {
    let path = `/register/collaborator`;
    navigate(path);
    localStorage.setItem("token", "KEAKLUHRQW786FAFIJEQWMJ12984RQJFWKJE");
    localStorage.setItem("collaborator", true);
    e.preventDefault();
  };

  const handleChangeOption = (e) => {
    setShowContainer(!showContainer);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (payload.username === "admin" && payload.password === "admin123") {
      localStorage.setItem("token", "KEAKLUHRQW786FAFIJEQWMJ12984RQJFWKJE");
      let path = `/`;
      navigate(path);
    } else {
      setIsToken(true);
      setTimeout(() => setIsToken(false), 4000);
    }
  }

  return (
    <>
      {localStorage.getItem("token") ? (
        <Home />
      ) : (
        <div className="container">
          <div className="dual-container">
            <button
              className={`change-option${
                !showContainer ? " option-login " : " collaborator"
              }`}
              onClick={handleChangeOption}
            >
              {!showContainer ? <p>Login</p> : <p>Colaborador</p>}
            </button>
            <div
              className={`container-login${
                !showContainer ? " hide-container" : ""
              }`}
            >
              <div className="content-login">
                <div className="container-title">
                  <p className="content-title-form">CRX</p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="input-field-box-login">
                    <input
                      type="text"
                      required
                      onChange={(e) => handleChange("username", e.target.value)}
                      value={payload.username}
                      name="username"
                    ></input>
                    <span className="bar"></span>
                    <label>Nome de usuário ou E-mail</label>
                  </div>
                  <div className="input-field-box-login">
                    <input
                      type="password"
                      required
                      onChange={(e) => handleChange("password", e.target.value)}
                      value={payload.password}
                      name="password"
                    ></input>
                    <span className="bar"></span>
                    <label>Senha</label>
                  </div>
                  {isToken ? (
                    <div className="input-field-wrong">
                      <div className="wrong-message">
                        Usuário ou senha incorretos{" "}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <button type="submit" className="button-sign">
                      ENTRAR
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div
              className={`content-collaborator${
                !showContainer ? " show-container" : ""
              }`}
            >
              <p className="welcome">Bem vindo</p>
              <div>
                Se você é um novo colaborador
                <p>
                  clique abaixo para se cadastrar
                  <p> e junte-se à nossa equipe</p>
                </p>
                <button
                  onClick={handleRedirect}
                  className="collaborator-link button-register"
                >
                  Cadastre-se como visitante.
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
